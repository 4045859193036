import React from 'react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import Banner from '@components/banner';
import DiscoverProducts from '@components/discoverProducts';
import { CategorySiteWorkers } from '@constants/app';

import '@styles/styles.scss';

const SiteworkersPage = ({ data }) => {
  const ctf = data.contentfulDiscoverPages;
  const {
    forSiteworkersBannerTitle,
    forSiteworkersBannerSubtitle,
    forSiteworkersBannerImageTitle,
    forSiteworkersTitle,
    forSiteworkersDescription
  } = ctf;
  const assets = data.allContentfulAsset.edges.map(edge => edge.node);

  const tradeUpDescription = ctf.tradeUpDescription.tradeUpDescription;
  const workUpDescription = ctf.workUpDescription.workUpDescription;
  const serviceUpDescription = ctf.serviceUpDescription.serviceUpDescription;
  const projectUpDescription = ctf.projectUpDescription.projectUpDescription;
  const checkUpDescription = ctf.checkUpDescription.checkUpDescription;
  const upFamilyDescription = ctf.upFamilyDescription.upFamilyDescription;

  return (
    <Layout>
      <SEO title="DiscoverSiteworkers"/>
      <Banner
        assets={assets}
        imageTitle={forSiteworkersBannerImageTitle}
      >
        <div className="d-flex flex-column mt-3 mb-5 mb-md-0">
          <h1>{forSiteworkersBannerTitle}</h1>
          <p className="text-muted">{forSiteworkersBannerSubtitle.forSiteworkersBannerSubtitle}</p>
        </div>
      </Banner>
      <DiscoverProducts
        productCategory={CategorySiteWorkers}
        title={forSiteworkersTitle}
        description={forSiteworkersDescription.forSiteworkersDescription}
        tradeUpDescription={tradeUpDescription}
        workUpDescription={workUpDescription}
        serviceUpDescription={serviceUpDescription}
        projectUpDescription={projectUpDescription}
        checkUpDescription={checkUpDescription}
        upFamilyDescription={upFamilyDescription}
      />
    </Layout>
  );
};

export default SiteworkersPage;

export const pageQuery = graphql`
  query DiscoverSiteworkersQuery {
    contentfulDiscoverPages {
      forSiteworkersBannerTitle
      forSiteworkersBannerSubtitle {
        forSiteworkersBannerSubtitle
      }
      forSiteworkersBannerImageTitle
      forSiteworkersTitle
      forSiteworkersDescription {
        forSiteworkersDescription
      }
      tradeUpDescription {
        tradeUpDescription
      }
      workUpDescription {
        workUpDescription
      }
      serviceUpDescription {
        serviceUpDescription
      }
      projectUpDescription {
        projectUpDescription
      }
      checkUpDescription {
        checkUpDescription
      }
      upFamilyDescription {
        upFamilyDescription
      }
    }
    allContentfulAsset {
      edges {
        node {
          title
          file {
            contentType
            url
            fileName
          }
          fixed {
            height
            width
            src
            srcSet
          }
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          } 
        }
      }
    }
  }
`;
